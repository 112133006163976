<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-8"
      collapse-action
      v-if="!$acl.check('hospitalgroup')"
    >
      <div class="vx-row">
        <!-- CountryID -->
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4" >
          <label class="text-sm opacity-75">{{ $t("Country") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="HospitalSearch.CountryID"
            :options="countries"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- HospitalGroupID -->
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Hospital Groups") }}</label>
          <v-select
            label="Name"
            class="mw-full"
            v-model="HospitalSearch.HospitalGroupID"
            :options="HospitalGroups"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- HospitalName -->
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Hospital Name") }}</label>
          <vs-input
            v-model="HospitalSearch.HospitalName"
            class="w-full"
            name="HospitalName"
          />
        </div>

        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <vs-button
            class="my-6 rounded-sm"
            @click="HospitalSearchFirst"
            color="warning"
            >{{ $t("Search") }}</vs-button
          >
        </div>

        <!-- Add New Button -->
        <div class="vx-col w-full">
          <vs-button
            type="border"
            icon-pack="feather"
            icon="icon-plus"
            @click="addNewData"
            >{{ $t("AddNew") }}</vs-button
          >
        </div>

        <!-- Export Button -->
        <div class="vx-col flex items-center">
          <vs-checkbox
            v-model="withActivates"
            color="success"
            class="mr-4"
          >With Activates?</vs-checkbox>
          <vs-button
            class="ml-6 mb-4"
            type="border"
            icon-pack="feather"
            icon="icon-download"
            @click="ExcelExport"
          >
            {{ $t("Export") }}
          </vs-button>
        </div>
      </div>
    </vx-card>

    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        v-for="item in Hospitals"
        :key="item.ID"
        @click="$router.push(item.url).catch(() => {})"
      >
        <vx-card class="vxCardPatient text-center cursor-pointer rounded-xl">
          <div class="vx-row ml-3 mr-3" style="justify-content: right">
            <feather-icon
              icon="TrashIcon"
              svgClasses="w-7 h-7 hover:text-primary stroke-current"
              @click="openConfirm(item)"
            />
          </div>
          <div class="vx-row">
            <imageLazy
              :imageStyle="{
                'border-radius': '40px',
                width: '80px',
                height: '80px',
                size: '100px',
              }"
              :imageClass="'ml-3'"
              :src="baseURL + item.ImagePath"
              placeHolderType="hospital"
            />
            <div class="vx-col mb-3 mt-3">
              <h4 class="mb-2">{{ item.Name }}</h4>
              <small @click="editData(item)">{{ "Profile Data" }}</small>
            </div>
          </div>

          <div class="vx-row mt-3">
            <div class="lg:w-1/2 w-full">
              <vs-button
                class="mr-2 customizer-btn rounded-lg text-sm"
                color="warning"
                style="width: 95%"
                type="filled"
                @click="ShowHospitalSurgeries(item)"
                >{{ $t("Hospital Surgeries") }}</vs-button
              >
            </div>
            <div class="lg:w-1/2 w-full">
              <vs-button
                class="mr-2 customizer-btn rounded-lg text-sm"
                color="warning"
                style="width: 95%"
                type="filled"
                @click="ShowHospitalDoctors(item)"
                >{{ $t("Hospital Doctors ") }}</vs-button
              >
            </div>
          </div>
          <div class="vx-row mt-3">
            <div class="lg:w-1/2 w-full">
              <vs-button
                class="mr-2 customizer-btn rounded-lg text-sm"
                color="warning"
                style="width: 95%"
                type="filled"
                @click="ShowPatientReservationSurgeries(item)"
                >{{ $t("Booked surgeries") }}</vs-button
              >
            </div>
            <div class="lg:w-1/2 w-full">
              <vs-button
                class="mr-2 customizer-btn rounded-lg text-sm"
                color="warning"
                style="width: 95%"
                type="filled"
                @click="ShowHospitalSpecialties(item)"
                >{{ $t("Hospital Specialties") }}</vs-button
              >
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import { domain } from "@/gloabelConstant.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleHospitalGroup from "@/store/settings/HospitalGroup/moduleHospitalGroup.js";
import moduleLanguage from "@/store/settings/language/moduleLanguage.js";
import { BAvatar } from "bootstrap-vue";
import imageLazy from "@/components/image_lazy.vue";
import axios from "@/axios.js";
export default {
  components: {
    BAvatar,
    imageLazy,
  },
  data() {
    return {
      withActivates: false,
      search: {
        IsPaging: true,
        PageNumber: 1,
        PageSize: 20,
      },
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      baseURL: domain,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false,
    };
  },
  computed: {
    HospitalSearch() {
      debugger;
      return this.$store.state.HospitalList.HospitalSearchObj;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    Hospitals() {
      return this.$store.state.HospitalList.Hospitals;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    specialities() {
      return this.$store.state.SpecialtyList.specialities;
    },
    HospitalGroups() {
      return this.$store.state.HospitalGroupList.HospitalGroups;
    },
    languages() {
      return this.$store.state.LanguageList.languages;
    },
  },

  methods: {

    ExcelExport() {
      debugger;
      const data = {
        tableName: 'Hospital',
        fileName: 'HospitalExport',
        SheetName: 'Hospital',
        CountryID: this.HospitalSearch.CountryID? this.HospitalSearch.CountryID : null,
        HospitalName: this.HospitalSearch.HospitalName? this.HospitalSearch.HospitalName : null,
        HospitalGroupID: this.HospitalSearch.HospitalGroupID? this.HospitalSearch.HospitalGroupID : null,
        withActivates: false,
        // DoctorName: this.HospitalSearch.DoctorName ?this.HospitalSearch.DoctorName : null,
        // HospitalID: this.HospitalSearch.HospitalID ?this.HospitalSearch.HospitalID: null,
        // AssignedAccountManagerID: this.HospitalSearch.AssignedAccountManagerID? this.HospitalSearch.AssignedAccountManagerID: null,
        // AppointmentDateFrom: this.HospitalSearch.AppointmentDateFrom?this.HospitalSearch.AppointmentDateFrom :null,
        // AppointmentDateTo: this.HospitalSearch.AppointmentDateTo ? this.HospitalSearch.AppointmentDateTo : null,
        // SurgeryDateFrom: this.HospitalSearch.SurgeryDateFrom ? this.HospitalSearch.SurgeryDateFrom : null,
        // SurgeryDateTo: this.HospitalSearch.SurgeryDateTo ? this.HospitalSearch.SurgeryDateTo : null

      };
      axios
        .post("Api/Common/ExportFileExcel", data)
        .then(() => {
          debugger;
          this.downloadExcelExport();
        })
        .catch((error) => console.error(error));
    },
    downloadExcelExport() {
  const fileName = 'HospitalExport.xlsx';
      debugger;
  axios
    .get("Uploads/" + fileName, { responseType: "blob" })
    .then(({ data }) => {
      const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => console.error(error));
},
    HospitalDataSearch() {
      debugger;
      this.$store.dispatch("HospitalList/SearchHospitalsPaging", this.HospitalSearch).then(
        (res)=>{
          if (
              document.documentElement.scrollHeight <= window.innerHeight &&
              res.data.Data.length >= this.HospitalSearch.PageSize
            ) {
              this.HospitalSearch.PageNumber++;
              this.HospitalDataSearch();
            }
        }
      )
      
    },
    HospitalSearchFirst() {
      debugger;
      this.HospitalSearch.PageNumber = 1;
      this.HospitalDataSearch();
    },
    addNewData() {
      // this.sidebarData = {};
      this.$router.push({ name: "Hospital-add/Edit" });
    },

    ShowHospitalSurgeries(data) {
      this.$router.push({
        name: "HospitalSurgeries",
        params: { ID: data.ID },
      });
    },
    ShowPatientReservationSurgeries(data) {
      this.$router.push({
        name: "PatientResevationSurgeries",
        params: { ID: data.ID },
      });
    },
    ShowHospitalSpecialties(data) {
      this.$router.push({
        name: "HospitalSpecialities",
        params: { ID: data.ID },
      });
    },
    ShowHospitalDoctors(data) {
      this.$router.push({
        name: "HospitalDoctors",
        params: { ID: data.ID },
      });
    },

    deleteData(data) {
      debugger;
      this.$store.dispatch("HospitalList/DeleteHospital", data).catch((err) => {
        console.error(err);
      });
    },

    editData(data) {
      this.$router.push({ name: "Hospital-add/Edit", params: { ID: data.ID } });
    },

    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },

    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });
    },
  },
  destroyed() {
    window.onscroll = () => {};
  },
  created() {
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    if (!moduleHospitalGroup.isRegistered) {
      this.$store.registerModule("HospitalGroupList", moduleHospitalGroup);
      moduleHospitalGroup.isRegistered = true;
    }
    if (!moduleLanguage.isRegistered) {
      this.$store.registerModule("LanguageList", moduleLanguage);
      moduleLanguage.isRegistered = true;
    }
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if(this.languages.length==0)
      this.$store.dispatch("LanguageList/GetAllLanguages");
    
    if(this.HospitalGroups.length==0)
      this.$store.dispatch("HospitalGroupList/GetAllHospitalGroups");
    
    if(this.specialities.length==0) 
      this.$store.dispatch("SpecialtyList/GetAllSpecialties");
    if(this.countries.length==0) 
       this.$store.dispatch("CountryList/GetAllCountries");

    if (this.$store.state.AppActiveUser.userRole == "accountmanager") {
      this.HospitalSearch.AssignedAccountManagerID = this.$store.state.AppActiveUser.uid;
    }
    else if(this.$acl.check('hospitalgroup'))
    {
         this.HospitalSearch.HospitalGroupID = this.$store.state.AppActiveUser.HospitalGroupID;
    }

    this.$store.commit("HospitalList/SET_Hospital", []);
    this.HospitalSearchFirst();
    // this.$store.dispatch("HospitalList/SearchHospitalsPaging", this.search);
  },
  mounted() {
    window.onscroll = () => {
      debugger;
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight + 80 >=
        document.documentElement.scrollHeight;
      debugger;
      if (bottomOfWindow) {
        if (this.Hospitals.length > 0) {
          debugger;
          this.HospitalSearch.PageNumber++;
          this.HospitalDataSearch();
        }
      }
    };
  },
};
</script>

<style lang="scss">
#data-list-list-view small {
  color: rgb(255, 115, 0);
  text-decoration: underline rgb(255, 115, 0);
}

.vxCard:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0%;
  margin-left: 10%;
  z-index: 500000;
  width: 25%;
  height: 20%;
  border-top: 3px solid rgb(255, 115, 0) !important;
  border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
  border-right: 0 none rgba(255, 0, 0, 0.9) !important;
  border-left: 0 none rgba(0, 0, 0, 0.9) !important;
}
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
